<!-- 培训详情 -->
<template>
	<div class="train">
		<div>
			<audio ref="audio" :src="course.audio" id="audio" @canplay="canplay"></audio>
			<!-- <audio ref="audio" src="http://www.gsotc.com.cn/uploads/files/20220831/6c89c6f16bfaa85c1b3ea99955128f66.m4a" id="audio" @canplay="canplay"></audio> -->
			<div v-if="type==2" class="ado-tle">{{course.title}}</div>
			<div class="audio" v-if="type==2">
				<div class="bof" @click="clkBf">
					<i class="iconfont" style="color: #fff;font-size: 24px;" v-if="!audioPlay">&#xea82;</i>
					<i class="iconfont" style="color: #fff;font-size: 24px;" v-else>&#xe628;</i>
				</div>
				<div class="jd1">
					<div class="jindu" @click="clkJd" ref="jindu">
						<div class="jd2" :style="jd2">
							<div class="circle">
								<div class="cir"></div>
							</div>
						</div>
					</div>
				</div>
				<div class="time">{{audioTime}}</div>
			</div>
			
			<!-- 图文 视频-->
			<div class="imgsss" v-else-if="type==1 || type==3">
				<div class="img" v-show="type==1">
					<img :src="course.thumb" alt="">
				</div>
				<div class="videocc" v-show="type==3">
					<video controls :src="course.video"></video>
				</div>
				<h3 class="title">{{course.title}}</h3>
			</div>
			<div class="cou-sub">
				<h4 class="cou-dail">课程简介</h4>
				<div style="font-size: 16px;" class="vhtml" v-html="course.subject"></div>
			</div>
			
			
			<ul class="us-tm-eye">
				<li>
					<i class="iconfont">&#xe661;</i>
					<p>{{course.hits}}</p>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
	import qs from "qs"
	import util from "@/assets/script/util.js"
	import moment from "moment"
	export default {
		data() {
			return {
				type:0,
				course:{},
				audioPlay:false, // 当前音频播放状态
				audioTime:"", // 播放时长转化为分的
				audioDura:0, // 播放总时长
				jd2:{}, // 音频播放进度
			}
		},
		created() {
			let id = this.$route.query.id
			this.InvestmentDetails(id)
			
		},
		mounted() {
			
			
		},
		methods:{
			// 点击进度
			clkJd(e) {
				let audio = this.$refs.audio;
				// 获取音频总时长
				let audioDura = audio.duration
				
				let jdlef = $(".jindu").offset().left
				// 获取当前点击距离初始距离
				let tarLeft = e.clientX-jdlef
				// 获取总宽度
				let jdWid = $(".jindu").width()
				
				let width = tarLeft/jdWid*100+"%"
				
				audio.currentTime = (tarLeft/jdWid)*audioDura
				
				let jd2 = {
					width:width
				}
				this.jd2 = jd2
				
			},
			InvestmentDetails(id) {
				let request = this.$route.query.queryPath
				let url = this.$api.base+request
				this.$axios.post(
					url,
					qs.stringify({id})
				).then(res=>{
					if(res.code) {
						this.type = res.data.type
						res.data.subject = util.setContent(res.data.subject)
						res.data.inputtime = moment((res.data.inputtime)*1000).format("YYYY-MM-DD")
						this.course = res.data
					}
				})
			},
			canplay() {
				let audio = this.$refs.audio;
				// 获取音频总时长
				let audioDura = audio.duration
				console.log(audioDura);
				// 获取总时长
				let time = util.formatSeconds(audioDura)
				this.audioTime = time
				
				let that = this
				
				audio.ontimeupdate = function() {
					// 获取当前播放时长
					let currentTime = this.currentTime
					
					let width = currentTime/audioDura*100+"%"
					let jd2 = {
						width:width
					}
					
					that.jd2 = jd2
				};
				
				audio.onended = function() {
					that.audioPlay = false
				}
				
			},
			// 点击播放
			clkBf() {
				let audio = this.$refs.audio;
				console.log(audio);
				// 获取总时长
				let audioDura = audio.duration
				console.log(audioDura);
				if(!this.audioPlay) {
					audio.play()
					
				}else{
					audio.pause()
				}
				this.audioPlay = !this.audioPlay	
			}
		},
	}
</script>

<style lang="less" scoped>
	.imgsss {
		.img {
			img {
				width: 100%;
			}
		}
		.videocc {
			video {
				width: 100%;
				vertical-align: bottom;
			}
		}
		.title {
			line-height: 33px;
			color: rgba(51, 51, 51, 1);
			font-size: 22px;
			font-weight: 700;
			margin: 30px 0 20px 0;
		}
		
	}
	.cou-sub {
		.cou-dail {
			line-height: 24px;
			color: rgba(51, 51, 51, 1);
			font-size: 16px;
			font-weight: 700;
			margin-bottom: 12px;
		}
	}
	.ado-tle {
		line-height: 33px;
		color: rgba(51, 51, 51, 1);
		font-size: 22px;
		font-weight: 700;
	}
	.video {
		position: relative;
		transition: all .5s;
		&:hover {
			&::after {
				display: block;
			}
			.icon {
				opacity: 1;
			}
		}
		&::after {
			display: none;
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: rgba(0,0,0,.5);
		}
		.icon {
			transition: all .5s;
			opacity: 0;
			background-color: lightgray;
			width: 50px;
			height: 50px;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			z-index: 9999;
			i {
				color: #fff;
			}
		}
		
	}
	
	.train {
		box-sizing: border-box;
		padding: 30px;
		.tle {
			line-height: 50px;
			color: rgba(51, 51, 51, 1);
			font-size: 34px;
			font-weight: 700;
			text-align: center;
			margin-top: 50px;
			margin-bottom: 60px;
		}
		.us-tm-eye {
			line-height: 21px;
			color: rgba(153, 153, 153, 1);
			font-size: 14px;
			font-weight: 500;
			display: flex;
			border-top: 0.5px solid rgba(235, 236, 239, 1);
			padding-top: 20px;
			margin-top: 20px;
			li {
				display: flex;
				align-items: center;
				margin-right: 40px;
				&:last-child {
					margin-right: 0;
				}
				i {
					margin-right: 5px;
				}
			}
		}
		.audio {
			display: flex;
			justify-content: space-between;
			align-items: center;
			box-sizing: border-box;
			padding: 16px;
			box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
			margin: 40px 0 60px 0;
			.bof {
				flex-shrink: 0;
				width: 40px;
				height: 40px;
				border-radius: 50%;
				background-color: var(--themeColor);
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;
			}
			.jd1 {
				box-sizing: border-box;
				padding: 0 30px;
				flex-grow: 1;
				.jindu {
					flex-grow: 1;
					position: relative;
					height: 8px;
					background: rgba(242, 242, 242, 1);
					border-radius: 4px;
					cursor: pointer;
					.jd2 {
						background-color: var(--themeColor);
						width: 0%;
						position: absolute;
						left: 0;
						top: 0;
						bottom: 0;
						border-radius: 4px;
						.circle {
							width: 16px;
							height: 16px;
							border-radius: 50%;
							background-color: #fff;
							position: absolute;
							right: 0;
							transform: translate(50%,-50%);
							display: flex;
							align-items: center;
							justify-content: center;
							top: 50%;
							box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
							.cir {
								width: 8px;
								height: 8px;
								background-color: var(--themeColor);
								border-radius: 50%;
							}
						}
					}
				
				}
			}
			
			.time {
				flex-shrink: 0;
				color: rgba(51, 51, 51, 1);
				font-size: 16px;
				font-weight: 400;
			}
		}
		.tle-cou {
			line-height: 24px;
			color: rgba(51, 51, 51, 1);
			font-size: 16px;
			font-weight: 700;
			margin-bottom: 12px;
		}
		.ctx {
			line-height: 24px;
			color: rgba(102, 102, 102, 1);
			font-size: 16px;
			font-weight: 400;
			padding-bottom: 30px;
		}
		.eye {
			color: rgba(153, 153, 153, 1);
			font-size: 14px;
			font-weight: 500;
			line-height: 21px;
			margin-top: 20px;
			i {
				margin-right: 5px;
			}
		}
	}
	@media (max-width:900px) {
		.train {
			padding: 0;
		}
		.video,.audio,.imgsss {
			margin: 20px 0 20px 0;
		}
	}
	@media (max-width:768px) {
		.ado-tle {
			font-size:20px;
		}
		.train {
			.audio {
				margin:20px 0;
				.jd1 {
					padding: 0;
					padding-left: 15px;
				}
				.bof {
					width:30px;
					height: 30px;
					i {
						font-size: 15px !important;
					}
				}
			}
		}
	}
</style>